import React from 'react';
import "./Contact.css";
import Contactimg from '../../Assets/contact.jpg';

const Contact = () => {
  return (
    <div className="contact-page">
  <div className="contact-container">
    {/* Form Section */}
    <div className="form-section">
      <h2>Contact Us</h2>
      <p>We’re here to help! Fill out the form and we’ll get back to you as soon as possible.</p>
      <form id="contact-form" className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Your Name</label>
          <input type="text" id="name" name="name" placeholder="Enter your name" required />
        </div>
        <div className="form-group">
          <label htmlFor="name">Your Company Name</label>
          <input type="text" id="name" name="name" placeholder="Enter your Company Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your Email</label>
          <input type="email" id="email" name="email" placeholder="Enter your email" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Your Message</label>
          <textarea id="message" name="message" rows="5" placeholder="Write your message here" required></textarea>
        </div>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>

    {/* Photo and Contact Info Section */}
    <div className="info-section">
      <img src={Contactimg} alt="Contact Us" className="info-image" />
      <div className="contact-info">
        <h3>Contact Information</h3>
        <p><strong>Location:</strong> First Floor, Near Butti Dass Mandir, Fatehgarh Churian,Distt Gurdaspur, 143602</p>
        <p><strong>Phone:</strong> +91 99140-64691</p>
        <p><strong>Email:</strong> freepathsolution@gmail.com</p>
        <p><strong>Hours:</strong> Mon-Sun: 9:00 AM - 6:00 PM</p>
      </div>
    </div>
  </div>
  <div className="map" style={{
    marginTop: '40px',
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
}}>
  <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3388.50643849147!2d74.95389727625198!3d31.86564343000125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1733824310893!5m2!1sen!2sin" width="100%" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
</div>
  )
}

export default Contact