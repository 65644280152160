import React, { useState } from "react";
import './Career.css';

const Career = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        position: "",
        message: "",
        resume: null,
    });

    const [status, setStatus] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, resume: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataObj = new FormData();
        for (const key in formData) {
            formDataObj.append(key, formData[key]);
        }

        try {
            const response = await fetch("http://freepathsolutions.com/send-email.php", {
                method: "POST",
                body: formDataObj,
            });
            const result = await response.json();
            if (result.success) {
                setStatus("Form submitted successfully!");
            } else {
                setStatus("Failed to submit form. Please try again.");
            }
        } catch (error) {
            setStatus("Error submitting form.");
        }
    };

    return (
        <div className="career-page">
            <header className="hero-section">
                <h1>Join Our Dream Team</h1>
                <p>Your future starts here. We are looking for talented, driven individuals to be part of our journey.</p>
            </header>

            <div className="form-container">
                <h2>Apply Now</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your full name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone:</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Position Applied For:</label>
                        <input
                            type="text"
                            name="position"
                            value={formData.position}
                            onChange={handleChange}
                            placeholder="Enter the position you're applying for"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Message:</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Tell us why you're a great fit!"
                            required
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label>Upload Resume:</label>
                        <input type="file" name="resume" onChange={handleFileChange} required />
                    </div>
                    <button type="submit" className="submit-btn">Submit</button>
                    {status && <p className="status-message">{status}</p>}
                </form>
            </div>
        </div>
    );
};

export default Career;
