import React from "react";
import "./About.css";
import Aboutimg from "../../Assets/about.jpg";

const About = () => {
  const team = [
    {
      name: "Mamta Arora",
      role: "Owner",
      photo: "https://ui-avatars.com/api/?name=Mamta+Arora&background=cc0000&color=fff", // Replace with actual photo
    },
    {
      name: "Rohit Arora",
      role: "Co founder",
      photo: "https://ui-avatars.com/api/?name=Rohit+Arora&background=cc0000&color=fff", // Replace with actual photo
    },
    {
      name: "Parth",
      role: "Advisor",
      photo: "https://ui-avatars.com/api/?name=Sidharth+Banaot&background=cc0000&color=fff", // Replace with actual photo
    },
    {
      name: "Rohit Sheoran",
      role: "Operations Manager",
      photo: "https://ui-avatars.com/api/?name=Rohit+Sheoran&background=cc0000&color=fff", // Replace with actual photo
    },
    {
      name: "Adarsha Sharma",
      role: "HR",
      photo: "https://ui-avatars.com/api/?name=Adarsha+Sharma&background=cc0000&color=fff", // Replace with actual photo
    },
  ];
  return (
    <section className="about" id="about">
      <div className="about-container">
        <div className="about-content">
          <h1>About Us</h1>
          <p>
            Welcome to <strong>Free Path Solutions</strong>, your trusted partner
            for back-office and customer service solutions. Founded by Rohit
            Arora, our company is dedicated to delivering top-tier services that
            empower businesses to focus on their core competencies while we take
            care of the rest.
          </p>
          <p>
            With over a decade of experience, we’ve supported businesses across
            industries with tailored solutions that drive efficiency, improve
            customer satisfaction, and ensure operational excellence. At Free
            Path Solutions, our mission is to help businesses thrive in today’s
            competitive market.
          </p>
          <p>
            Our commitment to professionalism, reliability, and innovation sets
            us apart. Let us handle your back-office and customer support
            challenges so you can concentrate on achieving your business goals.
          </p>
        </div>
        <div className="about-image">
          <img src={Aboutimg} alt="About Free Path Solutions" />
        </div>
      </div>
      {/* Team Section */}
      <div className="team-section">
        <h2>Meet Our Team</h2>
        <p className="team-intro">
          Our team is our greatest asset, and their expertise drives our
          success. Meet the talented individuals behind Free Path Solutions.
        </p>
        <div className="team-grid">
          {team.map((member, index) => (
            <div className="team-card" key={index}>
              <img src={member.photo} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
