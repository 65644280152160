import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import logo from "../../Assets/logo.png";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="logo"><span>Free Path Solutions</span></div>
      <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
        <a href="/" onClick={() => setIsMobile(false)}>Home</a>
        <a href="about" onClick={() => setIsMobile(false)}>About</a>
        <a href="services" onClick={() => setIsMobile(false)}>Services</a>
        <a href="contact" onClick={() => setIsMobile(false)}>Get a Quote</a>
        <a href="career" onClick={() => setIsMobile(false)}>Career</a>
      </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        {isMobile ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;