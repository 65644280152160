import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Home.css";

const Home = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [stats, setStats] = useState([]);
  const [services, setServices] = useState([]);
  const carouselRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  
  const team = [
    {
      name: "Mamta Arora",
      role: "Owner",
      photo: "https://ui-avatars.com/api/?name=Mamta+Arora&background=ddd&color=000", // Replace with actual photo
    },
    {
      name: "Rohit Arora",
      role: "Co founder",
      photo: "https://ui-avatars.com/api/?name=Rohit+Arora&background=ddd&color=000", // Replace with actual photo
    },
    {
      name: "Parth",
      role: "Advisor",
      photo: "https://ui-avatars.com/api/?name=Sidharth+Banaot&background=ddd&color=000", // Replace with actual photo
    },
    {
      name: "Rohit Sheoran",
      role: "Operations Manager",
      photo: "https://ui-avatars.com/api/?name=Rohit+Sheoran&background=ddd&color=000", // Replace with actual photo
    },
    {
      name: "Adarsha Sharma",
      role: "HR",
      photo: "https://ui-avatars.com/api/?name=Adarsha+Sharma&background=ddd&color=000", // Replace with actual photo
    },
  ];
  

  const scrollTestimonials = (direction) => {
    const container = carouselRef.current;
    const scrollAmount = container.offsetWidth;
    container.scrollBy({
      left: direction === "left" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  };

  const checkArrows = () => {
    const container = carouselRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft + container.offsetWidth < container.scrollWidth
      );
    }
  };

  useEffect(() => {
    const container = carouselRef.current;
    if (container) {
      container.addEventListener("scroll", checkArrows);
      checkArrows();
    }
    return () => {
      container?.removeEventListener("scroll", checkArrows);
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    setTestimonials([
      {
        quote: "Excellent After-Hours Services I had a great experience with Freepath Solution! Their after-hours services are exceptional, especially for email handling and customer support. They ensured seamless communication and quick resolutions, even outside regular business hours. The team is professional, responsive, and truly understands customer needs. Highly recommend their services to any business looking for reliable support!",
        author: "Shri anand travels",
      },
      {
        quote: "Outstanding Chat Support and Inbound Call Services Freepath Solutions exceeded my expectations with their excellent chat support and inbound call services. Their team is professional, responsive, and well-trained, ensuring a seamless experience for my customers. They handled every interaction with care and efficiency, making my business operations smoother and more reliable. Highly recommend them for anyone seeking top-notch support services!",
        author: "Mining discovery",
      },
      {
        quote: "Exceptional Support and Sales Services. As the owner of Chadda Real Estate, I am highly impressed with the services provided by Free Path Solutions. Their team has been instrumental in managing our outbound sales calls and handling inbound queries via email and phone. Their professionalism, efficiency, and attention to detail have greatly improved our customer interactions and sales processes. I highly recommend Free Path Solutions!",
        author: "Chadda Real Estate",
      },
      {
        quote: "Reliable Payroll and Tech Support Services. As the owner of Techtile, I am extremely satisfied with the services provided by Free Path Solutions. Their team excels in managing both inbound and outbound calls, as well as providing exceptional payroll and tech support to our clients. From troubleshooting technical issues to ensuring smooth operations, they have consistently delivered with professionalism and expertise. I highly recommend Free Path Solutions!",
        author: "Techtile",
      },
      {
        quote: "Excellent Chat Support and Payroll Services. As the owner of Parth Enterprises, I am highly impressed with the services provided by Free Path Solutions. Their team has been exceptional in managing chat support, ensuring smooth and efficient communication with our clients. Additionally, their payroll services have been a game-changer for handling staff salaries accurately and on time. Their professionalism and attention to detail make them a reliable partner. Highly recommend Free Path Solutions for top-quality support!",
        author: "Parth Enterprises",
      },
    ]);

    setStats([
      { value: "100+", label: "Clients Served Globally" },
      { value: "98.9%", label: "Customer Satisfaction Rate" },
      { value: "3+", label: "Years of Experience" },
      { value: "20+", label: "Expirenced Staff" },
      { value: "30%", label: "Revenue Impact" },
    ]);

    setServices([
      {
        title: "Inbound and Outbound Call Support",
        image: "http://freepathsolutions.com/assets/call.jpg",
      },
      {
        title: "Email Support Services",
        image: "http://freepathsolutions.com/assets/email.jpg",
      },
      {
        title: "Custom-Tailored Customer Service Solutions",
        image: "http://freepathsolutions.com/assets/customerservice.jpg",
      },
      {
        title: "Payroll Management",
        image: "http://freepathsolutions.com/assets/payroll.jpg",
      },
      {
        title: "Tech Support",
        image: "http://freepathsolutions.com/assets/techsupport.jpg",
      },
    ]);
  }, []);

  return (
    <section className="home" id="home">
  <div className="home-container">
    {/* Intro Section */}
    <div className="home-intro" data-aos="fade-up">
      <h1>Welcome to <span>Free Path Solutions</span></h1>
      <p>
        At Free Path Solutions, we specialize in providing world-class back-office services to empower your business. 
        With our efficient, reliable, and scalable solutions, your company can focus on what it does best—leaving the backend operations to us.
      </p>
      <a href="services" className="cta-button">Discover Our Services</a>
    </div>

    {/* Dynamic Services Section */}
    <div className="home-details" data-aos="fade-up">
      {services.map((service, index) => (
        <div className="details-item" key={index}>
          <img src={service.image} alt={service.title} />
          <h3>{service.title}</h3>
        </div>
      ))}
    </div>

    {/* Statistics Section */}
    <div className="home-stats" data-aos="fade-up">
      {stats.map((stat, index) => (
        <div className="stat-item" key={index}>
          <h3>{stat.value}</h3>
          <p>{stat.label}</p>
        </div>
      ))}
    </div>

    {/* Dynamic Testimonials Section */}
    <div className="home-testimonials" data-aos="fade-up">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-carousel">
        {showLeftArrow && (
          <button
            className="carousel-button left"
            aria-label="Scroll left"
            onClick={() => scrollTestimonials("left")}
          >
            &#8592;
          </button>
        )}
        <div className="carousel-content" ref={carouselRef}>
          {testimonials.map((testimonial, index) => (
            <div className="testimonial-item" key={index}>
              <p>{testimonial.quote}</p>
              <h4>{testimonial.author}</h4>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <button
            className="carousel-button right"
            aria-label="Scroll right"
            onClick={() => scrollTestimonials("right")}
          >
            &#8594;
          </button>
        )}
      </div>
    </div>

    {/* Call-to-Action (CTA) Section */}
    <div className="home-cta" data-aos="fade-up">
      <h2>Ready to Transform Your Business?</h2>
      <a href="contact" className="cta-button">Contact Us Today</a>
    </div>

    {/* Team Section */}
    <div className="home-team" data-aos="fade-up">
      <h2>Meet Our Team</h2>
      <div className="team-members">
        {team.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.photo} alt={member.name} />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
    </div>

  </div>
</section>

  );
};

export default Home;