import React from "react";
import "./Services.css";

const Services = () => {
  const services = [
    {
      title: "Inbound and Outbound Call Support",
      description:
        "Seamless communication is key to building trust. We ensure every customer call is handled professionally, leaving a lasting positive impression. From resolving queries to outbound sales, we cover all your call support needs.",
      image: "http://freepathsolutions.com/assets/call.jpg",
    },
    {
      title: "Email Support Services",
      description:
        "Efficient email communication is critical in a digital-first world. Our team delivers accurate and professional responses promptly, enhancing customer experience while saving you time.",
      image: "http://freepathsolutions.com/assets/email.jpg",
    },
    {
      title: "Custom-Tailored Customer Service Solutions",
      description:
        "Every business is unique. We design bespoke customer service strategies tailored to your specific needs, ensuring measurable results and scalable growth.",
      image: "http://freepathsolutions.com/assets/customerservice.jpg",
    },
    {
      title: "Payroll Management",
      description:
        "Simplify your payroll operations with our efficient solutions. From processing payments to ensuring compliance, we handle it all to keep your workforce happy and your operations seamless.",
      image: "http://freepathsolutions.com/assets/payroll.jpg",
    },
    {
      title: "Tech Support",
      description:
        "Our tech support services are designed to provide comprehensive troubleshooting assistance for a wide range of technical issues. Whether you're facing software glitches, hardware malfunctions, connectivity problems, or system errors, our team of experienced professionals is here to help.",
      image: "http://freepathsolutions.com/assets/techsupport.jpg",
    },
  ];

  return (
    <section className="services" id="services">
      <div className="services-container">
        <h1 className="services-heading">Our Services</h1>
        <p className="services-intro">
          At Free Path Solutions, we offer an array of services designed to
          empower businesses by streamlining back-office operations and
          delivering exceptional customer support.
        </p>
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img src={service.image} alt={service.title} />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
